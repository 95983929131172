import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useParams, navigate, useLocation } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { Button, Dialog as DialogUI, Alert, Loader } from '@refera/ui-web'
import { MultilineInput, Select } from '_components/inputs'
import { KeyboardDateTimePicker } from '@material-ui/pickers'

import {
  getButtonsAction,
  getGenericParameters,
  getServiceOrder,
  updateAppointmentDateSchedule,
  getCurrentTradesmanSchedule,
} from '_modules/service-orders/actions'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import Loading from '_components/loading'
import { ROUTES } from '_utils/constants'
import { BUTTONS_ACTION, GENERIC_PARAMETERS, STEP_STATUS } from '_utils/constants/service-order'
import { getStepStatusLogLoadingSelector } from '_modules/budget/selectors'
import { getBudgetByServiceOrder, getStepStatusLog } from '_modules/budget/actions'
import useStyles from './styles'
import { userSelector } from '_/modules/authentication/selectors'
import HeaderTitle from '_/components/header-title'
import moment from 'moment'
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
import useCanDoButtonAction from '_/hooks/use-can-do-button-action'
import useToggle from '_/hooks/use-toggle'
import { Danger as InfoDangerIcon } from '@refera/ui-icons'
import TradesmanReasonToRefuseDetailModal from '_components/modal/tradesman-refuse-modal'
import BudgetWithoutVisitDialog from '_/components/service-order/tradesman/modals/budget-without-visiting'
import SuggestDateModal from '_/components/service-order/tradesman/modals/suggest-date-modal'
import AcceptanceModal from '_/components/service-order/tradesman/modals/acceptance-modal'
import { ServiceOrderDetailsViews } from './service-order-details-views'
import { ScheduleExecutionModal } from './components/modals/schedule-service-execution'
import { ReprovedServiceDetails } from './ReprovedServiceDetails'
import * as Modal from '_components/modal/generic-modal'
import { useToast } from '_/hooks/use-toast'
import WarningIcon from '@mui/icons-material/Warning'
import { FormProvider, useForm } from 'react-hook-form'
import classNames from 'classnames'

import ViewInstallmentsButton from '_/components/budget/navbar/view-installments-button'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { PoolWarning } from '_/components/PoolWarning'
import { formatErrorMessage } from '../finance/utils/FormatErrorMessage'

const defaultDialog = {
  isOpen: false,
  subject: '',
  description: '',
  iconType: 'info',
  labelApprove: 'Ok, entendi',
  onApprove: () => {},
}

const RESCHEDULING_REQUESTER = [
  { value: '', label: 'Selecione' },
  { value: 'client', label: 'Cliente' },
  { value: 'tradesman', label: 'Prestador' },
  { value: 'other', label: 'Outro' },
]

const ServiceOrderDetails = () => {
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const stepStatusLoading = useSelector(getStepStatusLogLoadingSelector)
  const [checkOption, setCheckOption] = useState('')
  const [dialogInfo, setDialogInfo] = useState(defaultDialog)
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [modalSuggestDateOpen, setModalSuggestDateOpen] = useState(false)
  const [scheduleExecutionModalOpen, setScheduleExecutionModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useToggle()
  const [tradesmanSchedule, setTradesmanSchedule] = useState()
  const [isSuggestionOption, setIsSuggestionOption] = useState(false)
  const [isAcceptButtonEnabled, setIsAcceptButtonEnabled] = useState(false)
  const [modalVisitSchedule, setModalVisitSchedule] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [modalVisitScheduleConfirm, setModalVisitScheduleConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { pathname } = useLocation()
  const styles = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const [tradesmanReasonToRefuse, toggleTradesmanReasonToRefuse] = useToggle()
  const { checkUserPermission } = useRolePermission()
  const { showToast } = useToast()

  const [selectedDate, setSelectedDate] = useState(null)

  const user = useSelector(userSelector)

  const handleValidateServiceOrder = useCallback(async () => {
    dispatch(getServiceOrder(serviceOrderId))
      .then(order => {
        if (user?.getServiceProviderId !== order.tradesman && !order.tradesmanInPoolCandidates) {
          return navigate('/link-expirado')
        }
        return null
      })
      .catch(() => {
        return navigate('/nao-encontrado')
      })
    return null
  }, [dispatch, serviceOrder, serviceOrderId])

  const defaultValues = {
    reschedulingRequester: '',
    reschedulingReason: '',
  }

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })

  const { handleSubmit, watch } = methods
  const reschedulingRequester = watch('reschedulingRequester')
  const reschedulingReason = watch('reschedulingReason')

  useEffect(() => {
    let isDisabled
    if (serviceOrder?.visitSchedule && isTradesmanSchedule) {
      isDisabled = !selectedDate || !reschedulingRequester || !reschedulingReason
    } else {
      isDisabled = !selectedDate
    }
    setIsSaveButtonDisabled(isDisabled)
  }, [selectedDate, reschedulingRequester, reschedulingReason, serviceOrder?.visitSchedule])

  const handleAuthenticateSuccess = useCallback(() => {
    handleValidateServiceOrder()
    dispatch(getServiceOrder(serviceOrderId))
    dispatch(getButtonsAction())
  }, [dispatch, serviceOrderId])

  useEffect(() => {
    handleAuthenticateSuccess()
  }, [])

  useEffect(() => {
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.DELAYED,
      })
    )
  }, [dispatch])

  const tradesmanScheduleData = useCallback(() => {
    setIsLoading(true)
    dispatch(getCurrentTradesmanSchedule(serviceOrderId))
      .then(data => {
        setTradesmanSchedule(data?.serviceProvider)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [dispatch, serviceOrderId])

  useEffect(() => {
    tradesmanScheduleData()
  }, [serviceOrderId])

  const isTradesmanSchedule = tradesmanSchedule === serviceOrder?.tradesman

  const handleCloseSuggestModal = useCallback(() => {
    setModalSuggestDateOpen(false)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  const canOpenServiceExecutionDetails = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.SERVICE_EXECUTION,
  })

  const canApproveServiceOrder = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.TRADESMAN_ACCEPT_SERVICE_ORDER,
  })

  const canRefuseServiceOrder = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.TRADESMAN_REFUSE_SERVICE_ORDER,
  })

  const canScheduleVisit = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.WAITING_BUDGET_SCHEDULE,
  })

  const canScheduleServiceExecution = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.SCHEDULE_SERVICE_EXECUTION,
  })

  const hasValidSuggestedDate = () => {
    if (serviceOrder?.controlScheduleWhatsapp) {
      const firstDate = serviceOrder?.firstSuggestionDate
      const secondDate = serviceOrder?.secondSuggestionDate
      const thirdDate = serviceOrder?.thirdSuggestionDate
      const countValidDates = [firstDate, secondDate, thirdDate].filter(
        date => date !== undefined && date !== null
      ).length

      return countValidDates >= 2
    }
    return false
  }

  const checkIfCanSuggestATime = option => {
    const isValid = hasValidSuggestedDate()
    if (isValid) {
      setDialogInfo({
        isOpen: true,
        subject: 'Atenção.',
        iconType: 'info',
        labelApprove: 'Ok, entendi',
        description:
          'Você não pode aceitar um chamado que tenha datas de  visita sugeridas com a opção "Gostaria de sugerir um horário". Selecione outra opção para prosseguir. Caso não tenha disponibilidade para aceitar o chamado com as outras opções, você também pode recusar o chamado.',
        onApprove: () => setDialogInfo(prevState => ({ ...prevState, isOpen: false })),
      })
    } else {
      setModalSuggestDateOpen(option)
    }
  }

  const handleCheckChange = useCallback(
    event => {
      const { value } = event.target

      setCheckOption(value)
      setIsSuggestionOption(
        ['first_suggestion_date', 'second_suggestion_date', 'third_suggestion_date'].includes(value)
      )
      setIsAcceptButtonEnabled(!!optionList.find(object => object.code === value))
    },
    [serviceOrder]
  )

  const optionList = useMemo(() => {
    const defaultOptions = [
      {
        id: '4',
        name: 'Quero fazer orçamento sem visita',
        code: 'create_budget_without_visiting',
      },
      {
        id: '5',
        name: 'Gostaria de sugerir um horário',
        code: 'suggest_a_time',
      },
      {
        id: '6',
        name: 'Entrar em contato com o cliente mais tarde',
        code: 'contact_customer_later',
      },
    ]

    const getDateOption = (prefix, id) => {
      if (!serviceOrder?.[`${prefix}SuggestionDate`]) {
        return []
      }

      const date = serviceOrder?.[`${prefix}SuggestionDate`]
      const startTime = serviceOrder?.[`${prefix}StartTime`]?.substring(0, 5)
      const endTime = serviceOrder?.[`${prefix}EndTime`]?.substring(0, 5)
      const code = `${prefix}_suggestion_date`
      const formattedDate = date ? moment(date).format('DD/MM/yyyy') : ''

      if (moment(date).isSameOrAfter(moment(), 'day')) {
        return [
          {
            id,
            name: `${formattedDate} entre ${startTime} - ${endTime}`,
            code,
          },
        ]
      }
      return []
    }

    return [
      ...getDateOption('first', '1'),
      ...getDateOption('second', '2'),
      ...getDateOption('third', '3'),
      ...defaultOptions,
    ]
  }, [serviceOrder])

  const onAcceptService = useCallback(() => {
    if (checkOption === 'create_budget_without_visiting') {
      setModalDialog({
        isOpen: true,
        title: `Você gostaria de fazer o orçamento agora?`,
        subTitle: `Caso a sua escolha seja NÃO, você será direcionado para a listagem de chamados
                    aguardando orçamento e pediremos para entrar em contato com o cliente via WhatsApp com
                    uma mensagem pré-definida informando que você estará criando um orçamento para este serviço
                    o mais breve possível.`,
      })
    } else if (checkOption === 'suggest_a_time') {
      checkIfCanSuggestATime(checkOption === 'suggest_a_time')
    } else if (checkOption === 'contact_customer_later') {
      setDialogInfo({
        isOpen: true,
        iconType: 'info',
        labelApprove: 'Ok, entendi',
        subject: 'Esta opção não está disponível no momento',
        description:
          'Você pode escolher uma sugestão indicada pelo cliente, sugerir uma nova data para realizar a visita, ou se preferir, pode fazer o orçamento a distância. Escolha uma dessas opções e aceite o chamado novamente.',
        onApprove: () => setDialogInfo(defaultDialog),
      })
    } else {
      setModalOpen(isSuggestionOption)
    }
  }, [isSuggestionOption, checkOption])

  useEffect(() => {
    Promise.resolve(dispatch(getStepStatusLog(serviceOrder?.id)))
  }, [serviceOrder?.id])

  const handleBudget = useCallback(async () => {
    const serviceOrderTradesmanId = serviceOrder?.tradesman
    if (
      user?.getServiceProviderId !== serviceOrderTradesmanId &&
      !serviceOrder.tradesmanInPoolCandidates
    ) {
      return navigate('/link-expirado')
    }

    setIsLoading(true)
    checkUserPermission(PERMISSIONS.ORIGINAL_BUDGETS, PERMISSIONS_ACTIONS.READ, () =>
      dispatch(getBudgetByServiceOrder(serviceOrderId))
        .then(budgetId => {
          setIsLoading(false)
          navigate(`${ROUTES.BUDGET_VIEW}/${budgetId}`)
        })
        .catch(error => {
          if (error?.redirect === false) {
            showToast({ type: 'error', message: formatErrorMessage(error?.message) })
          } else {
            handleErrorGetBudget()
          }
          setIsLoading(false)
        })
    )
    return null
  }, [dispatch, serviceOrder?.activeBudget, serviceOrderId])

  const handleErrorGetBudget = useCallback(() => {
    return navigate('/nao-encontrado')
  }, [])

  const handleScheduleNewDate = useCallback(
    data => {
      if (moment(selectedDate).isBefore(moment())) {
        return showToast({
          type: 'error',
          message: 'A data e hora devem ser no futuro.',
        })
      }

      const isSuggestTimeOption = checkOption === 'suggest_a_time'

      const payload = {
        dateAndTime: moment(selectedDate)?.format(),
        externalId: serviceOrder?.externalId,
        reschedulingRequester: data.reschedulingRequester,
        reschedulingReason: data.reschedulingReason,
        isSuggestTimeOption,
      }
      setIsLoading(true)
      dispatch(updateAppointmentDateSchedule(serviceOrderId, payload))
        .then(() => {
          dispatch(getBudgetByServiceOrder(serviceOrderId))
          showToast({
            type: 'success',
            message: 'Ação executada com sucesso.',
          })
          setIsLoading(false)
          setModalVisitScheduleConfirm(false)
        })
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Não foi possível executar esta ação, tente novamente.',
          })
          setIsLoading(false)
        })
      return setModalVisitSchedule(false)
    },
    [selectedDate, dispatch, serviceOrder, serviceOrderId]
  )

  const showServiceReprovedReasonDetails = useMemo(() => {
    const checkStepStatusIsReproved =
      serviceOrder?.stepStatus === STEP_STATUS.SERVICE_FINALIZATION_REPROVED ||
      serviceOrder?.stepStatus === STEP_STATUS.SERVICE_FINALIZATION_REPROVED_REQUESTER

    return checkStepStatusIsReproved
  }, [serviceOrder])

  if (!serviceOrder || stepStatusLoading) {
    return <Loading />
  }

  return (
    <Grid className={styles.page}>
      <Loader hasBackdrop open={isLoading} />
      <HeaderTitle
        title={`Chamado ${serviceOrder?.id}`}
        backButtonAction={() => navigate(ROUTES.NEW_ORDERS)}
        className={styles.headerTitle}
      >
        <Grid className={styles.buttons}>
          {canRefuseServiceOrder && (
            <Button color="red" variant="secondary" onClick={toggleTradesmanReasonToRefuse}>
              Recusar chamado
            </Button>
          )}
          {canScheduleServiceExecution && (
            <Button
              color="primary"
              variant="secondary"
              onClick={() =>
                checkUserPermission(
                  PERMISSIONS.SCHEDULE_SERVICE_EXECUTION,
                  PERMISSIONS_ACTIONS.ADD,
                  () => setScheduleExecutionModalOpen(true)
                )
              }
            >
              Agendar execução do serviço
            </Button>
          )}
          {canOpenServiceExecutionDetails && (
            <Button
              color="primary"
              variant="secondary"
              onClick={() => {
                navigate(
                  `/chamado/${serviceOrderId}/orcamento/${serviceOrder?.activeBudget}/execucao/prestador/`,
                  { state: { from: pathname } }
                )
              }}
            >
              Execução do Serviço
            </Button>
          )}
          {canScheduleVisit && (
            <Grid className={styles.buttonContainer}>
              <Button
                variant="primary"
                color="primary"
                onClick={() => setModalVisitSchedule(true)}
                disabled={isLoading}
              >
                Agendar visita para orçar
              </Button>
            </Grid>
          )}
          {serviceOrder?.stepStatus !== STEP_STATUS.WAITING_SERVICE_ORDER_ACCEPTANCE && (
            <Grid className={styles.buttonContainer}>
              <Button
                variant="primary"
                color="primary"
                className={styles.button}
                onClick={handleBudget}
                disabled={isLoading}
              >
                Ver orçamento
              </Button>
            </Grid>
          )}
          <ViewInstallmentsButton />
          {canApproveServiceOrder && (
            <Button
              disabled={!isAcceptButtonEnabled}
              color="primary"
              variant="primary"
              onClick={onAcceptService}
            >
              Aceitar chamado
            </Button>
          )}
        </Grid>
      </HeaderTitle>
      <Grid component="main" className={styles.container}>
        {serviceOrder?.isVipClient && (
          <Alert
            severity="warning"
            title="ATENÇÃO: Chamado de um cliente importante para a Refera. Favor garantir um atendimento de alta qualidade."
            icon={<WarningIcon fontSize="medium" color="warning" />}
          />
        )}
        {showServiceReprovedReasonDetails && <ReprovedServiceDetails />}
        <PoolWarning stepStatus={serviceOrder?.stepStatus} style={{ width: '100%' }} />

        <ServiceOrderDetailsViews />
        {[
          STEP_STATUS.WAITING_SERVICE_ORDER_ACCEPTANCE,
          STEP_STATUS.WAITING_POOL_ACCEPTANCE,
          STEP_STATUS.WAITING_PRE_APPROVED_PROPOSAL_POOL,
        ].includes(serviceOrder?.stepStatus) &&
          (canRefuseServiceOrder || canApproveServiceOrder) && (
            <>
              <Grid className={styles.content}>
                <Typography component="p" variant="h5" className={styles.sectionTitle}>
                  Escolha uma das opções abaixo para aceitar esse chamado.
                </Typography>
                {serviceOrder?.firstSuggestionDate ||
                serviceOrder?.secondSuggestionDate ||
                serviceOrder?.thirdSuggestionDate ? (
                  <Typography component="p" variant="h5" className={styles.descriptionSection}>
                    Escolha um momento sugerido pelo cliente para realizar a visita e fazer o
                    orçamento
                  </Typography>
                ) : (
                  <Typography component="p" variant="h5" className={styles.descriptionSection}>
                    Escolha uma das opções para realizar a visita e fazer o orçamento
                  </Typography>
                )}
                <FormControl className={styles.content}>
                  <RadioGroup
                    name="radio-buttons-group"
                    value={checkOption}
                    className={[styles.content, styles.radioData]}
                  >
                    {optionList?.map(option => (
                      <FormControlLabel
                        className={styles.radioData}
                        onChange={handleCheckChange}
                        key={option.id}
                        value={option.code}
                        control={<Radio />}
                        label={option.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              {modalOpen && isSuggestionOption && (
                <AcceptanceModal
                  open={modalOpen}
                  onCancel={handleCloseModal}
                  checkOption={checkOption}
                />
              )}

              {!isSuggestionOption && (
                <SuggestDateModal open={modalSuggestDateOpen} onCancel={handleCloseSuggestModal} />
              )}

              <BudgetWithoutVisitDialog
                modalDialog={modalDialog}
                setModalDialog={setModalDialog}
                user={user}
              />

              <DialogUI
                open={dialogInfo?.isOpen}
                icon={InfoDangerIcon}
                type={dialogInfo?.iconType}
                subject={dialogInfo?.subject}
                description={dialogInfo?.description}
                labelApprove={dialogInfo?.labelApprove}
                onApprove={dialogInfo?.onApprove}
              />
            </>
          )}
      </Grid>
      <Modal.Root open={modalVisitSchedule} onClose={() => setModalVisitSchedule(false)}>
        <Modal.TitleModal title="Dados de visita" />
        <Modal.Content className={styles.dialogContent}>
          <Grid className={styles.modalColumn}>
            <FormProvider {...methods}>
              <form id="scheduleForm" onSubmit={handleSubmit(handleScheduleNewDate)}>
                <Grid>
                  <KeyboardDateTimePicker
                    ampm={false}
                    label={
                      <Typography className={styles.text}>
                        Visita para orçamento em
                        <b className={styles.required}> *</b>
                      </Typography>
                    }
                    value={selectedDate}
                    onChange={setSelectedDate}
                    format="dd/MM/yyyy HH:mm"
                    placeholder="DD/MM/AAAA às HH:MM"
                    emptyLabel="DD/MM/AAAA às HH:MM"
                    className={styles.selectPlaceholder}
                    InputLabelProps={{
                      style: { marginTop: '-10px' },
                    }}
                    InputProps={{
                      style: { borderBottomColor: '1px solid #E3E3E3' },
                    }}
                  />
                  {!selectedDate && selectedDate !== null && (
                    <FormHelperText className={styles.required}>Campo requerido</FormHelperText>
                  )}
                </Grid>
                {serviceOrder?.visitSchedule && isTradesmanSchedule && (
                  <>
                    <Grid item xs={12}>
                      <Select
                        label="Quem solicitou o reagendamento?"
                        name="reschedulingRequester"
                        options={RESCHEDULING_REQUESTER}
                        required
                        getKey={item => item.value}
                        getValue={item => item.value}
                        getLabel={item => item.label}
                        labelClasses={classNames(styles.inputLabel, styles.selectLabel)}
                        inputProps={{ displayEmpty: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MultilineInput
                        name="reschedulingReason"
                        label="Motivo do reagendamento"
                        minRows={8}
                        labelClasses={styles.inputLabel}
                        required
                        inputProps={{ maxLength: 1500 }}
                      />
                    </Grid>
                  </>
                )}
              </form>
            </FormProvider>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Modal.ButtonRed onClick={() => setModalVisitSchedule(false)}>Cancelar</Modal.ButtonRed>
          <Modal.ButtonFullBlue
            onClick={() => setModalVisitScheduleConfirm(true)}
            disabled={isSaveButtonDisabled}
          >
            Salvar
          </Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>

      <Modal.Root
        open={modalVisitScheduleConfirm}
        onClose={() => setModalVisitScheduleConfirm(false)}
      >
        <Modal.Content>
          <Modal.WarningIcon />
          <Modal.Title text="Caso você confirme, o solicitante será informado." />
          <Modal.Text>Tem certeza que deseja fazer esse agendamento?</Modal.Text>
        </Modal.Content>
        <Modal.Actions className={styles.actionsModal}>
          <Modal.ButtonRed onClick={() => setModalVisitScheduleConfirm(false)}>
            Cancelar
          </Modal.ButtonRed>
          <Modal.ButtonFullBlue type="submit" form="scheduleForm">
            Confirmar
          </Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>

      {scheduleExecutionModalOpen && (
        <ScheduleExecutionModal
          key="ScheduleExecutionModal"
          open={scheduleExecutionModalOpen}
          onClose={() => setScheduleExecutionModalOpen(false)}
          onCancel={() => setScheduleExecutionModalOpen(false)}
        />
      )}
      {canRefuseServiceOrder && (
        <TradesmanReasonToRefuseDetailModal
          open={tradesmanReasonToRefuse}
          handleModal={toggleTradesmanReasonToRefuse}
        />
      )}
    </Grid>
  )
}

export default React.memo(ServiceOrderDetails)
